@import "../../../scss/theme-bootstrap";

.lp-json-pollock.card {
  width: 252px !important;
  background-color: $color-white;
  border-radius: 12px;
  overflow: hidden;
  margin: 10px 0;
  padding: 0 !important;
  box-shadow: 0 0 8px $color-light-grey;
  display: flex;
  .lp-json-pollock-layout-horizontal {
    display: flex !important;
  }
  * {
    width: auto !important;
    border: none !important;
    padding: 0 !important;
    word-break: normal !important;
  }
  .lp-json-pollock-layout {
    max-width: 100% !important;
  }
  .lp-json-pollock-element-button {
    button {
      color: $color-darkest-grey !important;
      font-family: $base-font-family-roman;
      font-weight: 500;
      text-decoration: underline;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  /* full-offer-card-image, product-card-image */
  .BrnDej-1 img,
  .BrrFen-1 img {
    @include swap_direction(margin, 8px 12px 8px 16px !important);
    width: 54px !important;
    height: 54px !important;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    border-radius: 6px !important;
    object-fit: cover;
    overflow: hidden;
    user-select: none;
  }
  /* product-card-item-color */
  .BrrFen-2 {
    @include swap_direction(margin, 0 6px 8px 0 !important);
    width: 12px !important;
    height: 12px !important;
    display: inline-block !important;
    border-radius: 50% !important;
    font-size: 0;
    overflow: hidden;
    user-select: none;
  }
  /* product-card-color-title */
  .BrrFen-3 {
    max-width: 145px !important;
    margin: 0 0 8px !important;
    display: block !important;
    color: $color-dusty-grey !important;
    font-family: $base-font-family-roman !important;
    font-weight: 500 !important;
    font-size: 10px;
    line-height: 13px;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: initial !important;
    word-wrap: initial !important;
  }
  /* product-card-price-old */
  .BrrFen-4 {
    @include swap_direction(margin, 0 6px 15px 0 !important);
    max-width: 136px !important;
    display: block !important;
    color: $color-silver-grey !important;
    font-family: $base-font-family-roman !important;
    font-weight: 500 !important;
    font-size: 10px;
    text-decoration: line-through;
    line-height: 1;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: initial !important;
    word-wrap: initial !important;
  }
  /* product-card-price-new */
  .BrrFen-5 {
    max-width: 136px !important;
    margin: 0 0 15px !important;
    display: block !important;
    color: $color-darkest-grey-2 !important;
    font-family: $base-font-family-roman !important;
    font-weight: 500 !important;
    font-size: 10px;
    line-height: 1;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: initial !important;
    word-wrap: initial !important;
  }
  /* title */
  .BrnDej-2 {
    @include swap_direction(margin, .875rem 1rem .5rem 0 !important);
    max-width: 154px !important;
    display: block !important;
    color: $color-darkest-grey-2 !important;
    font-family: $base-font-family-roman !important;
    font-weight: 700 !important;
    font-size: 12px;
    line-height: 14px;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: initial !important;
    word-wrap: initial !important;
  }
  .BrnDej-3 {
    @include swap_direction(margin, 0 12px 6px 0 !important);
    max-width: 154px;
    display: inline-block !important;
    color: $color-dusty-grey !important;
    font-family: $base-font-family-roman !important;
    font-weight: 500 !important;
    font-size: 10px;
    line-height: 12px;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: initial !important;
    word-wrap: initial !important;
  }
  /* button-1 */
  .XrfY-1 {
    max-width: 126px !important;
    width: 126px !important;
    height: 100%;
    margin: 0 !important;
    padding: 10px 1.6px !important;
    min-height: initial !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-top: 1px solid $color-border-grey !important;
    text-transform: uppercase;
    overflow: hidden !important;
    text-overflow: ellipsis;
    word-break: initial !important;
    word-wrap: initial !important;
    user-select: none;
  }
  /* button-2 */
  .XrfY-2 {
    max-width: 125px !important;
    width: 125px !important;
    height: 100%;
    margin: 0 !important;
    padding: 10px 1.6px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-top: 1px solid $color-border-grey !important;
    border-left: 1px solid $color-border-grey !important;
    text-transform: uppercase;
    overflow: hidden !important;
    text-overflow: ellipsis;
    word-break: initial !important;
    word-wrap: initial !important;
    user-select: none;
  }
  /* button-self */
  .XrfY-3 {
    max-width: 252px !important;
    width: 252px !important;
    height: 100%;
    margin: 0 !important;
    padding: 10px 1.6px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-top: 1px solid $color-border-grey !important;
    text-transform: uppercase;
    overflow: hidden !important;
    text-overflow: ellipsis;
    word-break: initial !important;
    word-wrap: initial !important;
    user-select: none;
  }
  /* image-only-card */
  .Rc5Dej-1 {
    img {
      width: 252px !important;
      height: 104px !important;
      margin: 0 !important;
      display: inline-flex !important;
      align-items: center !important;
      justify-content: center !important;
      object-fit: cover;
      cursor: pointer;
      user-select: none;
    }
  }
  /* image-double-button-card */
  .qRnRDej-1 {
    img {
      width: 252px !important;
      height: 69px !important;
      margin: 0 !important;
      display: inline-flex !important;
      align-items: center !important;
      justify-content: center !important;
      object-fit: cover;
      user-select: none;
    }
  }
}
